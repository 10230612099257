// IE11 has a prefix for matches. Quick and dirty hack until we're able to dig into our polyfills and
// attempt to polyfill properly. We check that Element global exists
// as this code may be loaded and we may not be running in a browser
// https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
const matches = (typeof (Element) !== 'undefined' &&
  Element !== null) && (Element.prototype.matches || Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector);

export default (domElement, history) => (clickEvent) => {
  let context = clickEvent.target;

  if (!matches) {
    throw new Error('Unsupported browser; no `match` method available.');
  }

  // ohp-pjax is part of the webui component router extension spec
  while (context && !matches.call(context, 'a.ohp-pjax, .ohp-pjax a')) {
    context = context !== domElement && context.parentNode;
  }

  if (context) {
    clickEvent.preventDefault();
    const { pathname, search, hash } = context;

    history.push({
      pathname,
      search,
      hash,
    });
  }
};
