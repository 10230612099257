import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import matchPath from 'rudy-match-path';

import handlePjaxLinks from '../lib/handle-pjax-links';

/*
  This class takes a WebUIComponent and puts it inside a react component
  wrapper. This class needs to be extended to call the whole lifecycle of
  the WebUIComponent.
*/
export default class ComponentHost extends Component {
  static propTypes = {
    config: PropTypes.object,
    context: PropTypes.object,
    history: PropTypes.any.isRequired,
    onSubrouteChange: PropTypes.func.isRequired,
    component: PropTypes.func,
    /**
     * We expect the UI elements that application container needs
     * to be passed in as props. This function must return React
     * Components for an errorPage, or null.
     *
     * We must preserve the no *UI* contract in Shell to avoid
     * a hard dependency on a React DOM impl which will limit
     * the usage of Shell.
     */
    render404: PropTypes.func.isRequired,
    renderError: PropTypes.func.isRequired,
    // TODO: use this for the async render call to the component's render method
    renderSpinner: PropTypes.func.isRequired,
    request: PropTypes.func.isRequired,
    RootElement: PropTypes.elementType,
  };

  static defaultProps = {
    RootElement: React.forwardRef((props, ref) => <div key="web-ui-wrapper" ref={ref} />),
  };

  constructor(props) {
    super(props);

    this.state = {
      hasErrored: false,
      has404ed: false,
    };

    this.destroyRouteListener = () => ({});
    this.containerRef = createRef();
  }

  // actually render the Web UI Component *if* no errors occurred
  componentDidMount() {
    this.mountWUIC();
  }

  componentDidUpdate(prevProps) {
    // if the context or config has changed, we must destroy the current Web UI Component
    // and render a new one for the new patient/instance configuration
    if (prevProps.context !== this.props.context || prevProps.config !== this.props.config) {
      this.unmountWUIC();
      this.mountWUIC();
    }
  }

  componentWillUnmount() {
    this.unmountWUIC();
  }

  mountWUIC = () => {
    // *only* create the container when we're going to mount a Web UI Component, so that
    // some instance doesn't lurk in the constructor unnecessarily.
    const container = (this.container = document.createElement('div'));
    const { config: componentConfig, context: componentContext, history, component: WebUIComponent, request } = this.props;

    this.clickListener = container.addEventListener('click', handlePjaxLinks(container, history));
    componentContext.request = request;

    try {
      this.webuiComponent = new WebUIComponent({ componentConfig, componentContext, container });
      this.webuiComponent.render((err) => {
        if (err) {
          throw err;
        }
        this.saveWebUIRoutes();
        this.ensureComponentInDOM();
      });
    } catch (error) {
      console.error('Failed to render Web UI component', error); // eslint-disable-line no-console
      this.setState({ hasErrored: true }); // eslint-disable-line react/no-did-mount-set-state
    }
  };

  unmountWUIC = () => {
    this.destroyRouteListener();

    // really really remove the container, because React memory leaks if you don't
    if (this.container) {
      this.container.removeEventListener('click', this.clickListener);
      this.container.remove();
      try {
        this.containerRef.current.removeChild(this.container);
      } catch (err) {
        // noop; it's already out of the DOM so we don't care that it threw while we were attempting to remove it
      }
      this.container = undefined;
    }

    if (this.webuiComponent && this.webuiComponent.destroy) {
      this.webuiComponent.destroy();
    } else {
      // eslint-disable-next-line no-console
      console.warn('tried to call destroy when there was nothing to destroy');
    }
  };

  ensureComponentInDOM = () => {
    if (this.containerRef.current) {
      // To ensure that we respect the visual styles of the React component
      // (which is the parent of the Web UI) we transfer the styles to the
      // container that the Web UI was rendered into.
      // NOTE: we cant use the CSS { all: inherit } styles as it has no IE/Edge support.
      this.container.style.cssText = this.containerRef.current.style.cssText;
      this.containerRef.current.appendChild(this.container);
    }
  };

  evaluateSubRoutes = (location) => {
    const { component: { routes = {} } } = this.props;
    let routeFound = false;

    // This is a special case: is routes an empty object?
    //  If yes, then we should allow navigation to the component itself when / is accessed,
    //  instead of randomly showing a 404.  Any other subroute should trigger a 404.
    // We *used* to have logic here that checked if '/' was simply not defined (even if a component had other routes),
    //  and would let the application continue as normal in that case in order to compensate for Documents defining
    //  /view and /view/:id but no / -- but we've decided to either fix or workaround that elsewhere.
    if (location.pathname === '/' && Object.keys(routes).length === 0) {
      routeFound = true;
    } else {
      Object.keys(routes).forEach((routeRegex) => {
        const match = matchPath(location.pathname, { path: `/${routeRegex.slice(1)}`, exact: true, strict: false });
        if (match) {
          this.triggerWebUIRoute(routes[routeRegex], match);
          routeFound = true;
        }
      });
    }

    this.setState({ has404ed: !routeFound });
  };

  saveWebUIRoutes = () => {
    // we have access to the baseURL, so what we can actually do is listen for history changes and
    // then use something like path-to-regexp to know when to call the routes?
    const { onSubrouteChange } = this.props;

    // Subscribe to history change events for this component.
    this.destroyRouteListener = this.props.history.listen((location) => {
      // When the history receives a new entry we need to determine if there is a corresponding subroute from the WUI component to call
      this.evaluateSubRoutes(location);
      // And then we need to ripple this information to the parent of the application container
      onSubrouteChange(location);
    });
    this.evaluateSubRoutes(this.props.history.location);
  };

  triggerWebUIRoute = (subRouteAction, match) => {
    const routeArgs = Object.values(match.params);
    // 1. handler is `string` which we assume represents a function on the Web UI instance.
    if (typeof subRouteAction === 'string') {
      // disabling this rule because eslint isn't detecting the different this context.
      // eslint-disable-next-line prefer-spread
      this.webuiComponent[subRouteAction].apply(this.webuiComponent, routeArgs);
      // 2. handler is `function` which so we pass Web UI as `this` context as per API spec.
    } else if (typeof subRouteAction === 'function') {
      subRouteAction.apply(this.webuiComponent, routeArgs);
      // 3. Web UI has declared invalid handler
    } else {
      throw new Error('Unsupported route handler, value must be string or function');
    }
  };

  render() {
    const { renderError, render404, RootElement } = this.props;

    if (this.state.hasErrored) {
      return renderError();
    } else if (this.state.has404ed) {
      return render404();
    }

    return <RootElement ref={this.containerRef} />;
  }
}
