import React from 'react';

import ComponentHost from '../components/ComponentHost';

/**
 * `host` is a small HOC which allows a developer to embed a Web UI Component
 * inside a friendly React component.  As an example, one can write:
 *
 * ```javascript
 * const MyComponent = host()(someWebUIComponent).default;
 *
 * ...
 *
 * render() {
 *   return <MyComponent config="..." context="..." />
 * }
 * ```
 *
 * ... and `host` will take care of the full lifecycle management of the
 * given component.  It's made to be used with React.Suspense/lazy in order
 * to dynamically load components at runtime:
 *
 * ```javascript
 * const MyComponent = lazy(() => import('my-component').then(host()))
 * ```
 *
 * The one argument it accepts is the *name of the exported component*, which
 * allows us to support Web UI Components available at `module.Component` or
 * `module.default`:
 *
 * ```javascript
 * const MyComponent = lazy(() => import('my-component').then(host('Component')))
 * ```
 *
 * If no argument is provided, it assumes `default`.
 *
 * The implementation is a little convoluted, because it's a series of callbacks.
 *
 * The actual function takes a key.
 *
 * The returned function accepts a Javascript object, and returns an ES6 object
 * with that key extracted and re-homed to `default` (in order to be compatible)
 * with `React.lazy`.
 *
 * The function hosted on `default` is a simple React functional component which
 * just loads the Web UI Component Host.
 *
 * @argument {string} requestedExportName the key to extract a React Component from
 * @returns {function} a function that accepts a JS object and returns a new JS object
 *                     with the Web UI Component keyed at `requestedExportName`
 *                     rehomed at `default` and wrapped with a Web UI Component Host.
 */
export default (requestedExportName) => {
  // this whole thing is effectively
  // (arg) => (anotherArg) => (props) => <YourComponent />
  let namedExport = requestedExportName;
  if (!namedExport) {
    namedExport = 'default';
  }

  return (module) => {
    const webComponent = module[namedExport];

    return {
      default: props => <ComponentHost {...props} component={webComponent} />,
    };
  };
};
