import React, { Component, Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import createHistory from 'history/createMemoryHistory';

import host from '../lib/host';

function configureSystemJS(jspmConfig) {
  // Only set the config once; we know it's empty if SystemJS exists but
  // the config map doesn't.
  if (!SystemJS) {
    throw new Error('using the SystemJS wrapper, but SystemJS is not available globally.  Did you mean to use this wrapper?');
  }

  if (Object.keys(SystemJS.getConfig().map).length === 0) {
    SystemJS.config(jspmConfig);
  }
}

export default class SystemJSPorcelain extends Component {
  static propTypes = {
    componentContext: PropTypes.object,
    request: PropTypes.func.isRequired,
    jspmConfig: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    moduleMeta: PropTypes.object.isRequired,
    onSubrouteChange: PropTypes.func,
    subroute: PropTypes.string,
    /**
     * We expect the UI elements that application container needs
     * to be passed in as props. This functions must return React
     * Components for a loading spinner and an errorPage, or null.
     *
     * We must preserve the no *UI* contract in Shell to avoid
     * a hard dependency on a React DOM impl which will limit
     * the usage of Shell.
     */
    renderSpinner: PropTypes.func.isRequired,
    renderErrorMessage: PropTypes.func.isRequired,
    render404: PropTypes.func,
    RootElement: PropTypes.elementType,
    modules: PropTypes.object,
    // Determines whether the SystemJS fallback option should be used
    // In order to maintain backwards compatibility, this defaults to
    // true, but that will change in the next major revision.
    enableSystemJS: PropTypes.bool,
  };

  static defaultProps = {
    onSubrouteChange: () => ({}),
    subroute: '/',
    enableSystemJS: true,
  };

  constructor(props) {
    super(props);

    if (this.props.enableSystemJS) {
      console.warn('Deprecation warning: SystemJS fallback is enabled.  This will be disabled by default in the next major release.');
      configureSystemJS(this.props.jspmConfig);
    }

    this.history = createHistory({ initialEntries: [this.props.subroute] });
    this.realSubroute = this.props.subroute;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.subroute !== this.realSubroute) {
      // if only the subroute was set externally, we need to update history and the route pointer.
      this.realSubroute = nextProps.subroute;
      this.history.push(nextProps.subroute);
    }
  }

  onSubrouteChange = (newRoute) => {
    if (newRoute.pathname !== this.realSubroute) {
      this.realSubroute = newRoute.pathname;
      this.props.onSubrouteChange(newRoute);
    }
  }

  getComponent = () => {
    const { enableSystemJS, modules, moduleMeta, renderErrorMessage } = this.props;
    const { moduleName, moduleVersion, componentTypes } = moduleMeta;

    if (modules && modules[moduleName] && modules[moduleName][moduleVersion]) {
      return modules[moduleName][moduleVersion];
    }

    if (enableSystemJS) {
      // then there's no actual component, and we fall back to SystemJS
      return lazy(() =>
        SystemJS.import(moduleName)
        .then(host(componentTypes[0].export))
        .catch((err) => {
          console.error(err);
          return () => renderErrorMessage();
        })
      );
    }

    console.error('could not find requested component; check configuration');
    return () => renderErrorMessage();
  }

  render() {
    const { history, onSubrouteChange } = this;

    const {
      componentContext,
      moduleMeta,
      render404,
      renderErrorMessage,
      renderSpinner,
      request,
      RootElement,
      subroute,
    } = this.props;

    const HostedComponent = this.getComponent();

    // this looks weird; this is weird.  Suspense wants this in JSX syntax as a React element,
    // not as just a function.  So...
    const RenderSpinner = () => renderSpinner;

    return (
      <Suspense fallback={<RenderSpinner />}>
        <HostedComponent
          config={moduleMeta.componentConfig}
          context={componentContext}
          history={history}
          onSubrouteChange={onSubrouteChange}
          // backwards compatibility: pass through the error screen as a 404 screen,
          // because that was the existing behaviour
          render404={render404 || renderErrorMessage}
          renderError={renderErrorMessage}
          renderSpinner={renderSpinner}
          request={request}
          RootElement={RootElement}
          subroute={subroute}
        />
      </Suspense>
    );
  }
}
